import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {apiSlice} from "./apiSlice";

const calendarAdapter = createEntityAdapter({
    sortComparer: (a, b) => b.date.localeCompare(a.date)
});

const initialState = calendarAdapter.getInitialState();

export const calendarApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCalendars: builder.query({
            query: () => '/calendars',
            providesTags: (result, error, arg) => [
                { type: 'Calendar', id: "LIST" },
                ...result.map(id => ({ type: 'Calendar', id }))
            ]
        }),
        getMonths: builder.query({
            query: () => '/months',
            providesTags: (result, error, arg) => [
                { type: 'Month', id: "LIST" },
                ...result.map(id => ({ type: 'Month', id }))
            ]
        }),
        getDays: builder.query({
            query: () => '/days',
            providesTags: (result, error, arg) => [
                { type: 'Day', id: "LIST" },
                ...result.map(id => ({ type: 'Day', id }))
            ]
        }),
        getPlanets: builder.query({
            query: () => '/planets',
            providesTags: (result, error, arg) => [
                { type: 'Planet', id: "LIST" },
                ...result.map(id => ({ type: 'Planet', id }))
            ]
        }),
        getMoons: builder.query({
            query: () => '/moons',
            providesTags: (result, error, arg) => [
                { type: 'Moon', id: "LIST" },
                ...result.map(id => ({ type: 'Moon', id }))
            ]
        }),
    })
})

export const {
    useGetCalendarsQuery,
    useGetMonthsQuery,
    useGetDaysQuery,
    useGetPlanetsQuery,
    useGetMoonsQuery
} = calendarApiSlice

export const selectCalendarsResult = calendarApiSlice.endpoints.getCalendars.select()

// Creates memoized selector
const selectCalendarsData = createSelector(
    selectCalendarsResult,
    calendarsResult => calendarsResult.data
)

export const {
    selectAll: selectAllCalendars,
    selectById: selectCalendarById,
    selectIds: selectCalendarIds
} = calendarAdapter.getSelectors(state => selectCalendarsData(state) ?? initialState)

export const selectMonthsResult = calendarApiSlice.endpoints.getMonths.select()

// Creates memoized selector
const selectMonthsData = createSelector(
    selectMonthsResult,
    monthsResult => monthsResult.data
)

export const {
    selectAll: selectAllMonths,
    selectById: selectMonthById,
    selectIds: selectMonthIds
} = calendarAdapter.getSelectors(state => selectMonthsData(state) ?? initialState)

export const selectDaysResult = calendarApiSlice.endpoints.getDays.select()

// Creates memoized selector
const selectDaysData = createSelector(
    selectDaysResult,
    daysResult => daysResult.data
)

export const {
    selectAll: selectAllDays,
    selectById: selectDayById,
    selectIds: selectDayIds
} = calendarAdapter.getSelectors(state => selectDaysData(state) ?? initialState)