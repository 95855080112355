import React from 'react';

function Landing(props) {
    return (
        <div className={"landingPage"}>
            <h2>Welcome to Kor Sirok</h2>
            <p>
                Kor Sirok is a realm of political intrigue and conflict. Various civilizations vie for power
                and resources across the continents of the land. Magic and divine energies suffuse the world.
                Ancient civilizations have crumbled before, with the reasons for their fall lost to memory.
            </p>
            <p>
                Join the political schemes of various nobles and fiefdoms or delve into ancient ruins in
                search of treasures long forgotten. Kor Sirok is a thriving world that hides a dark and
                dangerous past. Adventurers will find much to search through as this land reveals itself.
                Travel is rarely safe and often difficult. In addition to the schemes of rival kingdoms and factions,
                there are ancient beasts and untamed savage creatures that roam the world.
            </p>

            <p>
                Dwarves, elves, halflings, gnomes, humans and other races rule various regions of the world.
                More compete for rarer resources in a darker more chaotic underground land below the surface.
                Few commoners have traveled outside the kingdom they call home, fewer still have traveled
                across the seas to other lands.
            </p>

            <p>
                Do you have what it takes to rise above the rest? Can you harness the power of an ever
                shifting political landscape? Discover lost histories and relics? Defeat powers beyond
                the comprehension of others? Can you master the realm of Kor Sirok?
            </p>
        </div>
    );
}

export default Landing;