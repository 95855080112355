import React from 'react';
import Navbar from "./Navbar";


function Header(props) {
    return (
        <header>
            <div className={"headerLeft"}>
                <img className={"headerLogo"} src={require("../512_Icon.png")} alt={"Kor Sirok logo"}/>
                <h1>Kor Sirok</h1>
            </div>
            <Navbar />
        </header>
    );
}

export default Header;