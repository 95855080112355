import React from 'react';

function MonthPicker(props) {
    const { months, setMonId } = props

    return (
        <>
            <select name={"month-select"} id={"month-select"} onChange={e => setMonId(e.target.value)}>
                {months.map(m => <option key={m.id} value={m.id}>{m.name}</option>)}
            </select>
        </>
    );
}

export default MonthPicker;