import React from 'react';

function YearPicker(props) {
    const { calendar, setYear } = props

    const content = []

    for (let i=calendar?.startingYear; i < calendar?.duration + calendar?.startingYear; i++) {
        content.push(<option key={i} value={i}>{i}</option>)
    }

    return (
        <>
            <select name={"year-select"} id={"year-select"} onChange={e => setYear(e.target.value)}>
                {content.map(c => c)}
            </select>
        </>
    );
}

export default YearPicker;