import React from 'react';
import Calendar from "../components/Calendar/Calendar";

function Weather(props) {
    return (
        <div>
            <Calendar />
        </div>
    );
}

export default Weather;