import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {apiSlice} from "./apiSlice";
import {sub} from "date-fns";

const adventureAdapter = createEntityAdapter({
    sortComparer: (a, b) => b.date.localeCompare(a.date)
});

const initialState = adventureAdapter.getInitialState();

export const adventureApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAdventures: builder.query({
            query: () => '/adventures',
            transformResponse: responseData => {
                let min = 1;
                const loadedAdventures = responseData.map(adventure => {
                    if (!adventure?.createdDate) adventure.createdDate = sub(new Date(), {minutes: min++}).toISOString();
                    return adventure;
                });
                return adventureAdapter.setAll(initialState, loadedAdventures)
            },
            providesTags: (result, error, arg) => [
                {type: 'Adventure', id: "LIST"},
                ...result.ids.map(id => ({type: 'Adventure', id}))
            ]
        }),
    })
})

export const {
    useGetAdventuresQuery
} = adventureApiSlice

export const selectAdventuresResult = adventureApiSlice.endpoints.getAdventures.select()

// Creates memoized selector
const selectAdventuresData = createSelector(
    selectAdventuresResult,
    adventuresResult => adventuresResult.data
)

export const {
    selectAll: selectAllAdventures,
    selectById: selectAdventureById,
    selectIds: selectAdventureIds
} = adventureAdapter.getSelectors(state => selectAdventuresData(state) ?? initialState)