import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {store} from "./contexts/store";
import {Provider} from "react-redux";
import {calendarApiSlice} from "./adapters/calendarSlice";
import {adventureApiSlice} from "./adapters/adventureSlice";

const root = ReactDOM.createRoot(document.getElementById('root'));

store.dispatch(calendarApiSlice.endpoints.getCalendars.initiate());
store.dispatch(calendarApiSlice.endpoints.getMonths.initiate());
store.dispatch(calendarApiSlice.endpoints.getMoons.initiate());
store.dispatch(calendarApiSlice.endpoints.getDays.initiate());
store.dispatch(calendarApiSlice.endpoints.getPlanets.initiate());
store.dispatch(adventureApiSlice.endpoints.getAdventures.initiate());

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path="/*" element={<App />} />
                </Routes>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);