import React from 'react';

function DayPicker(props) {
    const { days, setDayId } = props


    return (
        <>
            <select name={"day-select"} id={"day-select"} onChange={e => setDayId(e.target.value)}>
                {days.map(d => <option key={d.id} value={d.id}>{d.name}</option>)}
            </select>
        </>
    );
}

export default DayPicker;