import React, {useState} from 'react';
import CalendarPicker from "./CalendarPicker";
import MonthPicker from "./MonthPicker";
import DayPicker from "./DayPicker";
import YearPicker from "./YearPicker";
import {useGetCalendarsQuery, useGetDaysQuery, useGetMonthsQuery} from "../../adapters/calendarSlice";

function Calendar() {

    const
        [calId, setCalId] = useState(3),
        [year, setYear] = useState(1),
        [monId, setMonId] = useState(1),
        [dayId, setDayId] = useState(1)

    const { data: calendars, errorC, isLoadingC } = useGetCalendarsQuery()
    const { data: months, errorM, isLoadingM } = useGetMonthsQuery()
    const { data: days, errorD, isLoadingD } = useGetDaysQuery()

    const calendar = calendars?.filter(c => c.id === calId)[0]
    const month = months?.filter(m => m.id === monId)[0]
    const day = days?.filter(d => d.id === dayId)[0]

    return (
        <div className={"calendar-control"}>
            {(errorC || errorM || errorD) ? (
                <>Oh no, there was an error with the calendar!</>
            ) : (isLoadingC || isLoadingM || isLoadingD) ? (
                <>Loading...</>
            ) : (calendars && months && days) ? (
                <>
                    <h2>{`${calendar?.short}-${year}-${month?.short}-${day?.short}-##`}</h2>
                    <CalendarPicker calendars={calendars} setCalId={setCalId} />
                    <YearPicker calendar={calendar} setYear={setYear} />
                    <MonthPicker months={months?.filter(m => calendar?.monthIdList.includes(m.id))} setMonId={setMonId} />
                    <DayPicker days={days?.filter(d => calendar?.dayIdList.includes(d.id))} setDayId={setDayId} />
                </>
            ) : null}
        </div>
    );
}

export default Calendar;