import React from 'react';

function CalendarPicker(props) {
    const { setCalId, calendars } = props

    return (
        <>
            <select name={"calendar-select"} id={"calendar-select"} onChange={e => setCalId(e.target.value)}>
                {calendars.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
            </select>
        </>
    );
}

export default CalendarPicker;