import { Routes, Route } from "react-router-dom"
import Layout from "./components/Layout";
import Landing from "./pages/Landing";
import Weather from "./pages/Weather";
import Adventures from "./pages/Adventures";

function App() {
  return (
    <Routes>
        <Route path={"/"} element={<Layout />}>
            <Route index element={<Landing />} />

            <Route path={"weather"}>
                <Route index element={<Weather />}/>
            </Route>
            <Route path={"adventures"}>
                <Route index element={<Adventures />}/>
            </Route>
        </Route>
    </Routes>
  );
}

export default App;
