import React from 'react';
import {selectAdventureIds, useGetAdventuresQuery} from "../../adapters/adventureSlice";
import {useSelector} from "react-redux";
import AdventureExcerpt from "./AdventureExcerpt";

function AdventureList(props) {
    const {
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAdventuresQuery()

    const orderedAdventureIds = useSelector(selectAdventureIds)

    let content;
    if (isLoading) {
        content = <p>"Loading..."</p>;
    } else if (isSuccess) {
        content = orderedAdventureIds.map(adventureId => <AdventureExcerpt key={adventureId} adventureId={adventureId} />)
    } else if (isError) {
        content = <p>{error}</p>;
    }

    return (
        <section>
            {content}
        </section>
    );
}

export default AdventureList;