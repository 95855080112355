import React from 'react';
import MenuItem from "./MenuItem";
import "../App.css"

function Navbar(props) {
    const menuItems = [
        { title: "Home", url: "/" },
        { title: "Weather", url: "/weather" },
        { title: "Adventures", url: "/adventures" },
        { title: "VTTs", submenu: [
                { title: "Foundry", url: "http://korsirok.ddns.net/" },
                { title: "Roll20", url: "https://app.roll20.net/campaigns/details/4315340/utera" }
            ]}
    ]


    return (
        // <nav>
        //     <ul>
        //         <li><Link to={"/"}>Home</Link></li>
        //         <li><Link to={"/weather"}>Weather</Link></li>
        //         <li><Link to={"/adventures"}>Adventures</Link></li>
        //         <li><a href="http://korsirok.ddns.net/" target="_blank">FoundryVTT</a></li>
        //     </ul>
        // </nav>
        <nav>
            <ul className="menus">
                {menuItems.map((menu, index) => {
                    const depthLevel = 0;
                    return (
                        <MenuItem
                            items={menu}
                            key={index}
                            depthLevel={depthLevel}
                        />
                    );
                })}
            </ul>
        </nav>
    );
}

export default Navbar;