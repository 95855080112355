import React from 'react';
import {useSelector} from "react-redux";
import {selectAdventureById} from "../../adapters/adventureSlice";

function AdventureExcerpt(props) {
    const {adventureId} = props;
    const adventure = useSelector(state => selectAdventureById(state, adventureId))

    return (
        <article>
            <h2>{adventure.title}</h2>
            <p className={"excerpt"}>{adventure.text.substring(0, 75)}{adventure.text.length > 75 ? "..." : null}</p>
        </article>
    );
}

export default AdventureExcerpt;